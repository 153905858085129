import React, { useState, useEffect, useRef } from "react";
import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";
import Viewer from './Viewer';
import { Button, Modal } from "react-bootstrap";

const NewsFeed = () => {
  const [newsData, setNewsData] = useState([]);
  const [hovered, setHovered] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "News"));
      const querySnapshot = await getDocs(q);

      const news = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setNewsData(news);

      console.log(news);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    let animationFrameId;
    let initialTimestamp;

    const updateScrollPosition = (timestamp) => {
      if (!initialTimestamp) {
        initialTimestamp = timestamp;
      }

      const progress = timestamp - initialTimestamp;
      const newYPosition = Math.min(progress / 60, 500);

      if (container) {
        container.scrollTop = newYPosition;

        if (!hovered && newYPosition >= 280) {
          container.scrollTop = 0;
          initialTimestamp = null;
        }

        if (!hovered && newYPosition < 500) {
          animationFrameId = requestAnimationFrame(updateScrollPosition);
        }
      }
    };

    if (!hovered) {
      animationFrameId = requestAnimationFrame(updateScrollPosition);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [hovered]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const [show, setShow] = useState(false);
  const [news, setNews] = useState(null);

  const handleClose = () => {
    setNews(null)
    setShow(false);
  }
  const handleShow = (v) => {
    setNews(v)
    setShow(true);
  }
  return (
    <div className="news-feed-container-parent">
      <h2>News Feed</h2>
      <div
        className="news-feed-container"
        style={{ width: "400px", height: "500px", cursor: "pointer" }}
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          {newsData.map((news, i) => (
            <div key={i} className="news-item" onClick={() => handleShow(news)}>
              <h4 style={{ fontSize: '1.1rem' }}>{news.field1}</h4>
              <div className="">
                <Viewer html={news.field2} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{news?.field1}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Viewer html={news?.field2} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewsFeed;
