import React, { useEffect, useState } from "react";
import AppCarousel from "../Components/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import NewsFeed from "../Components/NewsFeed";
import EventComponent from "../Components/Events";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";

function Home() {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const text1Doc = await getDoc(doc(db, "Texts", "text1"));
        setText1(text1Doc.data() || "");

        const text2Doc = await getDoc(doc(db, "Texts", "text2"));
        setText2(text2Doc.data() || "");

        const text3Doc = await getDoc(doc(db, "Texts", "text3"));
        setText3(text3Doc.data() || "");
      } catch (error) {
        console.error("Error fetching texts:", error);
      }
    };

    fetchData();
  }, []);
  const [carouselData, setCarouselData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "Slider"));
      const querySnapshot = await getDocs(q);

      const event = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCarouselData(event);

      console.log("yuu", event);
    };

    fetchData();
  }, []);

  return (
    <div>
      <section id="">
        <Container>
          <Row className="p-2">
            <Col sm={12} md={12}>
              <AppCarousel props={carouselData} />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="about">
        <Container>
          <Row className="p-2">
            <Col sm={12} md={8}>
              <Row>
                <h2>About us</h2>
                <Col md={6} className="py-2">
                  <h4>{text1?.title}</h4>
                  <Markdown remarkPlugins={[remarkGfm]}>{text1?.text}</Markdown>
                </Col>
                <Col md={6} className="py-2">
                  <h4>{text2?.title}</h4>
                  <Markdown remarkPlugins={[remarkGfm]}>{text2?.text}</Markdown>
                </Col>
                <Col md={12} className="py-2">
                  <h4>{text3.title}</h4>
                  <Markdown remarkPlugins={[remarkGfm]}>{text3?.text}</Markdown>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={4}>
              <NewsFeed />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="forms">
        <Container>
          <Row className="p-2">
            <Col sm={6} lg={4}>
              <div className="form-sec">
                <h4 className="bg-p2 p-2 text-white">Notice Board</h4>
                <div className="">
                  <div className="p-2">
                    <a href="#scd" className="text-decoration-none ps-2">
                      Admission Form
                    </a>
                    <hr />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={4}>
              <div className="form-sec">
                <h4 className="bg-p2 p-2 text-white">Important Links</h4>
                <div className="">
                  <div className="p-2">
                    <a href="#scd" className="text-decoration-none ps-2">
                      Admission Form
                    </a>
                    <hr />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="events">
        <Container>
          <Row className="p-2">
            <Col md={12}>
              <EventComponent />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
export default Home;
