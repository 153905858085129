import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { db } from "../firebase";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";

function AboutUs() {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const text1Doc = await getDoc(doc(db, "Texts", "text1"));
        setText1(text1Doc.data() || "");

        const text2Doc = await getDoc(doc(db, "Texts", "text2"));
        setText2(text2Doc.data() || "");

        const text3Doc = await getDoc(doc(db, "Texts", "text3"));
        setText3(text3Doc.data() || "");
      } catch (error) {
        console.error("Error fetching texts:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <section id="">
        <Container>
          <Row className="p-2">
            <Col sm={12} md={12}></Col>
          </Row>
        </Container>
      </section>
      <section id="about">
        <Container>
          <Row>
            <h2>About us</h2>
            <Col md={10} className="py-2">
              <h4>{text1?.title}</h4>
              <Markdown remarkPlugins={[remarkGfm]}>{text1?.text}</Markdown>
            </Col>
            <Col md={6} className="py-5">
              <h4>{text2?.title}</h4>
              <Markdown remarkPlugins={[remarkGfm]}>{text2?.text}</Markdown>
            </Col>
            <Col md={12} className="py-2">
              <h4>{text3.title}</h4>
              <Markdown remarkPlugins={[remarkGfm]}>{text3?.text}</Markdown>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default AboutUs;
