import React from 'react';
import { Link } from 'react-router-dom';

const Logo = (props) => {
  return <img {...props} as={Link} to="/" src="/xeena_logo.jpg" alt="Xeena" />
};

export const LogoSecondary = (props) => {
  return <img {...props} as={Link} to="/" src="/Maulana_Abul_Kalam_Azad_University_of_Technology_Logo.svg.png" alt="MAKAUT" />
};

export default Logo;
